<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header fw-bold" style="display: flex;justify-content: space-between;">
                        <div>
                        <a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Order Details
                        </div>
                        <div style="display: flex;">
                            <button @click="invoiceDownload()">Invoice</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4" >
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Order No</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            {{orderInfo.orderNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Name</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            {{orderInfo.name}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Phone</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            {{ orderInfo.phone }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Email</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            {{ orderInfo.email }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="padding-left: 0px;" v-if="orderInfo.image">
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;" v-if="orderInfo.image">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Deposit Invoice</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            <a :href="env.mediaUrl+orderInfo.image" target="_blank"><img :src="env.mediaUrl+orderInfo.image" class="tbl_image"/></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;" v-if="orderInfo.image2">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Remain Invoice</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            <a :href="env.mediaUrl+orderInfo.image2" target="_blank"><img :src="env.mediaUrl+orderInfo.image2" class="tbl_image"/></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Remark</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            {{ orderInfo.remark }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="padding-left: 0px;">
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-right fw-bold">Total Price:</label>
                                        <div class="col-sm-8 fw-normal">
                                            {{ orderInfo.price }} ฿
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-right fw-bold">Deposit Price:</label>
                                        <div class="col-sm-8 fw-normal">
                                            {{ orderInfo.depositPrice }} ฿
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-right fw-bold">Final Price:</label>
                                        <div class="col-sm-8 fw-normal">
                                            {{ orderInfo.remainPrice }} ฿
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 border-top mb-3"></div>
                            <div class="col-md-4" >
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Order Date</label>
                                        <div class="col-sm-8 text-left fw-normal">:
                                            {{orderInfo.orderDate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" >
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Deposit Type</label>
                                        <div class="col-sm-8 text-left fw-normal text-uppercase">:
                                            {{orderInfo.deposit_type}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" >
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-4 text-left fw-bold">Remain Type</label>
                                        <div class="col-sm-8 text-left fw-normal text-uppercase">:
                                            {{orderInfo.remain_type}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 border-top mb-3"></div>
                            <div class="col-md-12 mt-2">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <thead class="bg-info text-light">
                                            <tr>
                                                <th>SKU</th>
                                                <th>Image</th>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td class="tbl_pd_50">{{data.sku}}</td>
                                                <td class="tbl_pd_50"> <a :href="env.mediaUrl+data.image" target="_blank"><img :src="env.mediaUrl+data.image" class="tbl_image"/></a></td>
                                                <td class="tbl_pd_50">{{data.product}}</td>
                                                <td class="tbl_pd_50">{{data.price}} ฿</td>
                                                <td class="tbl_pd_50">{{data.quantity}}</td>
                                                <td class="tbl_pd_50">
                                                    <span class="badge badge-pill badge-primary" v-if="data.status == 0">Pending</span>
                                                    <span class="badge badge-pill badge-success" v-if="data.status == 1">Confirm (Deposit)</span>
                                                    <span class="badge badge-pill badge-danger" v-if="data.status == 2">Reject</span>
                                                    <span class="badge badge-pill badge-success" v-if="data.status == 3">Completed (Remain)</span>
                                                    <span class="badge badge-pill badge-warning" v-if="data.status == 4">Processing</span>
                                                </td>
                                                <td class="tbl_pd_bt_50">
                                                    <button  class="btn btn-option text-primary" v-b-modal.bv-modal-infostatus @click="statusInfo(data)"> <i class="fas fa-edit"></i></button>
                                                    <!-- <button v-if="data.status == 1" class="btn btn-option text-primary" disabled> <i class="fas fa-edit"></i></button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-status" ref="my-modal" title="Status" hide-footer>
            <div class="d-block text-center">
                <p class="text-danger">{{this.alert}}</p>
                <select class="form-control" v-model="status">
                    <option value="0">Pending</option>
                    <option value="1">Confirm</option>
                    <option value="2">Shipping</option>
                    <option value="3">Delivered</option>
                </select>
                <input type="date" class="form-control mt-2" v-model="shippingDate" placeholder="Shipping Date">
                <input type="text" class="form-control mt-2" v-model="carrierName" placeholder="Carrier Name">
                <input type="text" class="form-control mt-2" v-model="trackingNo" placeholder="Tracking No">
            </div>
            <b-button class="mt-3 btn-success" block @click="statusUpdate()">Change</b-button>
        </b-modal>
        <b-modal id="bv-modal-infostatus" ref="my-modal" title="Info Status" hide-footer>
            <select class="form-control" v-model="status">
                <option value="0">Pending</option>
                <option value="1">Confirm (Deposit)</option>
                <option value="2">Reject</option>
                <option value="3">Completed (Remain)</option>
                <option value="4">Processing</option>
            </select>
            <b-button v-if="!orderInfoRequest.url" class="mt-3 btn-success" block @click="infoStatusUpdate()">Submit To Confirm</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import VueQrcode from 'vue-qrcode-component';
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'saleOrderDetail',
        metaInfo: {
            title: "Sale Order Details",
            titleTemplate: "%s ← RJ Dashboard",
        },
        components: {
            VueQrcode,
            Loading
        },
        data() {
            return {
                size: 300,
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                status: "",
                shippingDate: "",
                carrierName: "",
                trackingNo: "",
                dataList: [],
                orderInfo: [],
                orderInfoRequest: {
                    url: "",
                    infoId: "",
                    productName: "",
                    quantity: "",
                    status: "",
                    userId: "",
                    orderNo: "",
                    email: "",
                },
                filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    shopId: "",
                    fromDate: "",
                    toDate: "",
                    status: "",
                }
            }
        },
        watch: {
            '$route' (to, from) {
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                saleOrderInfoAction: 'saleOrderInfoAction',
                saleOrderUpdateAction: 'saleOrderUpdateAction',
                saleOrderDetailUpdateAction: 'saleOrderDetailUpdateAction',
                invoiceDownloadAction: 'invoiceDownloadAction'
            }),
            async getDetail(){
				let option = {
					id: this.id,
                    type: "update"
				}
				await this.saleOrderInfoAction({
					...option
				}).then(res => {
					this.orderInfo = res.data.detail
                    this.status = res.data.detail.status
                    this.dataList = res.data.detail.orderInfo
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            copyToClipboard(textToCopy) {
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                    alert('Text copied to clipboard: ' + textToCopy);
                    })
                    .catch(err => {
                    console.error('Failed to copy text: ', err);
                    });
                },
            back(data) {
                this.$router.replace({ path: 'order-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, shopId: this.filter.shopId, fromDate: this.filter.fromDate, toDate: this.filter.toDate, 
                    status: this.filter.status, userId: this.filter.userId, email: this.filter.email, orderNo: this.filter.orderNo } }).catch(()=>{})
            },
            statusDetail(){
                this.alert = ""
            },
            statusInfo(data){
                this.alert = ""
                this.orderInfoRequest.infoId = data.id
                console.log(this.orderInfoRequest.infoId)
                this.orderInfoRequest.status = data.status
                this.orderInfoRequest.url = data.url
            },
            async statusUpdate() {
				this.isLoading = true
				let option = {
					id:  this.id,
                    status: this.status,
                    shippingDate: this.shippingDate,
                    carrierName: this.carrierName,
                    trackingNo: this.trackingNo
				}
				await this.saleOrderUpdateAction({
					...option
				}).then(res => {
                    this.getDetail()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            async infoStatusUpdate() {
				this.isLoading = true
				let option = {
                    status:  this.status,
					orderInfoId:  this.orderInfoRequest.infoId
				}
                console.log(option)
				await this.saleOrderDetailUpdateAction({
					...option
				}).then(res => {
                    this.getDetail()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            async invoiceDownload() {
				this.isLoading = true
				let option = {id:  this.id}
                console.log(option)
				await this.invoiceDownloadAction({
					...option
				}).then(res => {
                    const filePath = env.mediaUrl + res.data.path; // Combine base URL and the file path from response
    
                    // Trigger the file download
                    // this.downloadFile(filePath);
                    window.open(filePath, '_blank');
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            downloadFile(filePath) {
            // Trigger file download using FileSaver.js or an alternative method
            fetch(filePath)
                .then(response => response.blob())
                .then(blob => {
                // Trigger file download
                const fileName = filePath.split('/').pop(); // Extract file name from the URL path
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = fileName;
                document.body.appendChild(a);  // Append link to DOM
                a.click();  // Trigger the download
                document.body.removeChild(a);  // Clean up the DOM
                })
                .catch(error => {
                console.error('Error during file download:', error);
                });
            }

        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.id = this.$route.query.id
            this.filter.fromDate = this.$route.query.fromDate
            this.filter.toDate = this.$route.query.toDate
            this.filter.status = this.$route.query.status
            this.filter.userId = this.$route.query.userId
            this.filter.email = this.$route.query.email
            this.filter.orderNo = this.$route.query.orderNo
            this.getDetail()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .qr-code-container {
        display: inline-block; /* Ensure the container wraps around the QR code */
        border: 1px solid black; /* Add border around the container */
        padding: 4px;
    }
</style>